import React from "react";
import { Modal } from 'react-bootstrap';
import './Subscription.scss';
import Cart from "./Cart";
import api from "../../../Environment";
// import ReactGA from 'react-ga';
import Helper from "../../Helper/helper";

// ReactGA.modalview('/subscription');

class Subscription extends Helper {

    state = {
        subscriptions: [],
        showModalCart : false,
        dataPropsCart : []
    }

    cart(params) {
        document.getElementById("header").style.zIndex = 1;
        // console.log(params);
        this.setState({
          showModalCart: true,
          dataPropsCart: params,
        });
      }

    handleCloseCart() {
      this.setState({ showModalCart: false });
    }

    componentDidMount() {
        // api call
        const data = {
          sub_profile_id: "",
        };
    
        api.postMethod("subscription_plans", data).then((response) => {
          if (response.data.success === true) {
            let subscriptions = response.data.data;
            this.setState({ 
                subscriptions: subscriptions 
            });
          } else {
            this.errorCodeChecker(response.data.error_code);
          }
        });
      }

    render(props) {
        const { isDoublePayment, videoDetailsFirst } = this.props;
        const {
            subscriptions,
            loading,
            showModalCart,
            dataPropsCart
          } = this.state;
        return (
            <Modal className="modal-pay-1" show={this.props.show} onHide={() => this.props.onHide(false)}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="container wrapper-pay-1">
                        <div className="row">

                        {loading
                        ? ""
                        : subscriptions.map((listData, index) =>
                            listData.length === 0 ? "" : 
                            <div className="col-md-4 nopadding">
                                <div className="section-content">
                                    <div className="content-header">
                                            <div className="content-header-title">{listData.title}</div>
                                    </div>
                                    <div className="content-body">
                                            <div className="content-body-title">PLAN</div>
                                            <div className="content-body-title-price">Rp {this.formatRupiah(listData.amount)} / {listData.plan_formatted}</div>
                                    </div>
                                    <div className="content-footer">
                                            <div className="content-footer-title">{listData.description}</div>

                                            <button id="get-it-now" className="btn-gin" onClick={() => this.cart(listData)}>Add to Cart</button>

                                            
                                    </div>
                                </div>
                            </div>
                        )}
                        </div>
                        <Cart
                            show={showModalCart}
                            dataPropsCart={dataPropsCart}
                            onHide={this.handleCloseCart.bind(this)}
                            isDoublePayment={isDoublePayment}
                            videoDetailsFirst={videoDetailsFirst}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default Subscription;